import config from '@/config/config'
import { LEADERBOARD_TYPE, QUEUE_TYPE_PATH } from '@/_helpers/enums'
import { handleResponse, handleFetchError } from '@/_helpers/error-handling.js'

export async function fetchLeaderboard(
  queueType,
  duration = null,
  lastTimestamp = null
) {
  const params = new URLSearchParams()
  if (duration && duration != undefined && duration != 'undefined') {
    params.append('duration', duration)
  }

  if (lastTimestamp) {
    params.append('lastTimestamp', lastTimestamp)
  }

  const requestOptions = {
    method: 'GET',
  }

  return fetch(
    `${config.endpoints.leaderboard}/${
      QUEUE_TYPE_PATH[queueType]
    }?${params.toString()}`,
    requestOptions
  ).then((response) => {
    if (response.status == 304) return response.status
    return handleResponse(response)
  }, handleFetchError)
}

export async function fetchDelta(queueType, lastTimestamp, duration) {
  const params = new URLSearchParams({ duration, lastTimestamp })
  const requestOptions = {
    method: 'GET',
  }

  return fetch(
    `${config.endpoints.delta}/${
      QUEUE_TYPE_PATH[queueType]
    }?${params.toString()}`,
    requestOptions
  ).then((response) => {
    if (response.status == 404 || response.status == 410)
      return Promise.reject(response.status)
    return handleResponse(response)
  }, handleFetchError)
}

export async function fetchDeltas(queueType) {
  const params = new URLSearchParams()
  const requestOptions = {
    method: 'GET',
  }

  return fetch(
    `${config.endpoints.deltas}/${
      QUEUE_TYPE_PATH[queueType]
    }?${params.toString()}`,
    requestOptions
  ).then((response) => {
    return handleResponse(response)
  }, handleFetchError)
}

export async function fetchAllTimeLeaderboard(queueType, lastTimestamp = null) {
  return fetchUnofficialLeaderboard(
    queueType,
    LEADERBOARD_TYPE.ALL_TIME,
    lastTimestamp
  )
}

export async function fetchPeakMmrLeaderboard(queueType, lastTimestamp = null) {
  return fetchUnofficialLeaderboard(
    queueType,
    LEADERBOARD_TYPE.PEAK_MMR,
    lastTimestamp
  )
}

function fetchUnofficialLeaderboard(
  queueType,
  leaderboardType,
  lastTimestamp = null
) {
  const params = new URLSearchParams()

  if (lastTimestamp) {
    params.append('lastTimestamp', lastTimestamp)
  }

  const requestOptions = {
    method: 'GET',
  }

  return fetch(
    `${config.endpoints.leaderboard}/${
      QUEUE_TYPE_PATH[queueType]
    }${leaderboardType}?${params.toString()}`,
    requestOptions
  ).then((response) => {
    if (response.status == 304) return response.status
    return handleResponse(response)
  }, handleFetchError)
}
