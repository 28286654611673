<template>
  <VueFixedHeader @fixed-header-changed="onFixedHeaderChange">
    <div class="scribe-controls">
      <div class="controls-wrapper">
        <div class="controls">
          <div class="scribe-button-group nav-buttons">
            <a
              :href="duelLeaderboardLink"
              @click.prevent="setQueueTypeToDuel"
              class="scribe-button nav-button"
              :class="{ active: isDuelSelected }"
            >
              Duel
            </a>
            <a
              :href="teamfightLeaderboardLink"
              @click.prevent="setQueueTypeToTeamfight"
              class="scribe-button nav-button"
              :class="{ active: isTeamfightSelected }"
            >
              Teamfight
            </a>
          </div>
        </div>
      </div>
      <div class="controls-wrapper">
        <div class="controls">
          <div class="toggle-controls">
            <div class="single-toggle-controls">
              <button
                class="scribe-button"
                :class="{ glow: !searchClicked && !searchActive }"
                @click="toggleSearch"
                :aria-label="
                  searchActive ? 'open delta settings' : 'open search'
                "
              >
                <vue-feather type="search" v-show="!searchActive" size="15" />
                <vue-feather type="triangle" v-show="searchActive" size="14" />
              </button>
              <button
                v-show="settingsAvailable"
                class="scribe-button"
                @click="toggleSettings"
                :aria-label="
                  settingsActive ? 'close settings' : 'open settings'
                "
              >
                <vue-feather
                  type="settings"
                  v-show="!settingsActive"
                  size="15"
                />
                <vue-feather type="x" v-show="settingsActive" size="15" />
              </button>
            </div>
            <DeltaControls
              v-show="showDeltas"
              :is-enabled="deltaControlsEnabled"
            />
            <ScribeSettings
              v-show="settingsActive && settingsAvailable"
              @done="onSettingChosen"
            />
            <Search
              v-show="searchActive"
              @search-success="searchActive = false"
            />
          </div>
        </div>
      </div>
    </div>
  </VueFixedHeader>
</template>

<script>
import Search from '@/components/PlayerSearch'
import DeltaControls from '@/components/DeltaControls'
import ScribeSettings from '@/components/ScribeSettings'
import { mapActions, mapState } from 'pinia'
import {
  QUEUE_TYPE,
  LEADERBOARD_TYPES_SUPPORTING_DELTA,
} from '@/_helpers/enums'
import { useMainStore } from '@/stores/main'
import VueFixedHeader from '@/components/VueFixedHeader'
import { useSelectedLeaderboard } from '@/stores/selectedLeaderboard'

const NAVIGATION_PREVENTING_ROUTES = ['PlayerProfilePage', 'HistoricalTopXPage']

export default {
  components: {
    DeltaControls,
    VueFixedHeader,
    Search,
    ScribeSettings,
  },
  data() {
    return {
      searchActive: false,
      settingsActive: false,
    }
  },
  beforeMount() {
    if (!this.isOfficial) this.settingsActive = true
  },
  computed: {
    ...mapState(useMainStore, [
      'selectedQueueType',
      'searchClicked',
      'selectedLeaderboardType',
    ]),
    ...mapState(useSelectedLeaderboard, ['isOfficial']),
    showDeltas() {
      return (
        !this.searchActive && (!this.settingsActive || !this.settingsAvailable)
      )
    },
    isDuelSelected() {
      return this.selectedQueueType == QUEUE_TYPE.DUEL
    },
    isTeamfightSelected() {
      return this.selectedQueueType == QUEUE_TYPE.TEAMFIGHT
    },
    deltaControlsEnabled() {
      return (
        LEADERBOARD_TYPES_SUPPORTING_DELTA.includes(
          this.selectedLeaderboardType
        ) || NAVIGATION_PREVENTING_ROUTES.includes(this.$route.name)
      )
    },
    duelLeaderboardLink() {
      return `/duel${this.selectedLeaderboardType}`
    },
    teamfightLeaderboardLink() {
      return `/teamfight${this.selectedLeaderboardType}`
    },
    settingsAvailable() {
      if (this.$route.name == null) return false
      return this.$route.name.endsWith('LeaderboardPage')
    },
  },
  methods: {
    ...mapActions(useMainStore, ['setSelectedQueueType', 'setSearchClicked']),
    setQueueTypeToDuel() {
      this.setQueueType(QUEUE_TYPE.DUEL)
    },
    setQueueTypeToTeamfight() {
      this.setQueueType(QUEUE_TYPE.TEAMFIGHT)
    },
    setQueueType(queueType) {
      this.setSelectedQueueType(queueType)
      if (
        !NAVIGATION_PREVENTING_ROUTES.includes(this.$route.name) &&
        this.$route.path != `/${queueType}${this.selectedLeaderboardType}`
      ) {
        this.$router.push(`/${queueType}${this.selectedLeaderboardType}`)
      }
    },
    toggleSearch() {
      this.settingsActive = false
      if (!this.searchClicked) {
        this.setSearchClicked()
      }
      this.searchActive = !this.searchActive
      if (this.searchActive) {
        setTimeout(() => {
          document.getElementById('search-input').focus()
        }, 50)
      }
    },
    toggleSettings() {
      this.settingsActive = !this.settingsActive
      this.searchActive = false
    },
    onSettingChosen(selectedLeaderboard) {
      if (LEADERBOARD_TYPES_SUPPORTING_DELTA.includes(selectedLeaderboard))
        this.settingsActive = false
    },
    onFixedHeaderChange(isFixed) {
      this.$emit('fixed-header-changed', isFixed)
    },
  },
}
</script>

<style lang="scss" scoped>
.scribe-controls {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: $background-color;
  z-index: 201;

  @media (min-width: $base-width) and (min-height: $base-height) {
    min-height: $controls-height;
  }
  @media (max-width: $base-width) and (max-height: $base-height) {
    padding-bottom: 0.5rem;
  }
}

.scribe-controls.vue-fixed-header--isFixed {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
}

.single-toggle-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  margin-right: 0.3rem;

  > .scribe-button {
    width: 3rem;
  }

  @media (max-width: $base-width) {
    flex-direction: column-reverse;

    > .scribe-button {
      width: 2.5rem;
    }
  }
}

.toggle-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 90%;
  flex-grow: 1;
  height: 100%;

  @media (max-width: $base-width) {
    margin: 0 0.3rem;
    max-width: 100%;
  }
}

.nav-buttons {
  flex-grow: 1;
  max-width: 90%;

  @media (max-width: $base-width) {
    margin: 0 0.3rem;
    max-width: 100%;
  }
}

.nav-button {
  @include font-size(1.2rem);
  white-space: nowrap;
  overflow: hidden;
  padding: 0.2rem 0.2rem;
  min-width: 2rem;
  text-overflow: ellipsis;
  outline: none;
  text-align: center;
  text-decoration: none;

  @media (min-width: $base-width) {
    width: 45%;
  }
}

.glow {
  z-index: 202;
  box-shadow: 0 0 1rem #ffd96f;
  transition: box-shadow 0.5s ease;
  &:hover {
    box-shadow: 0 12px 30px rgba($background-color, 0.6);
  }
}
</style>
