<template>
  <div class="update-wrapper" v-if="updateReady">
    <strong>An update for Scribe is available!</strong>
    <transition name="transition-quick" mode="out-in">
      <button
        v-if="!refreshing"
        class="scribe-button scribe-button-big app-update-button"
        @click="refreshApp"
      >
        Update now
      </button>
      <div v-else>...</div>
    </transition>
  </div>
</template>

<script>
import { GLOBAL_EVENT } from '@/_helpers/enums.js'

export default {
  data() {
    return {
      refreshing: false,
      registration: null,
      updateReady: false,
    }
  },
  created() {
    document.addEventListener(
      GLOBAL_EVENT.SERVICE_WORKER_UPDATE_READY,
      (event) => {
        this.registration = event.detail
        this.updateReady = true
      }
    )
  },
  methods: {
    refreshApp() {
      this.updateReady = false
      if (!this.registration || !this.registration.waiting) {
        return
      }

      navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (this.refreshing) return
        this.refreshing = true
        window.location.reload()
      })
      const registration = this.registration
      this.registration = null
      registration.waiting.postMessage('skipWaiting')
    },
  },
}
</script>

<style lang="scss" scoped>
.update-wrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 1rem 0;
}

.app-update-button {
  margin-top: 0.5rem;
}
</style>
