// @preval

const devConfig = require('./config.dev.js')
const prodConfig = require('./config.prod.js')

let config = {
  endpoints: {},
  appUpdateCheckInterval: 30 * 60 * 1000,
}
if (process.env.VUE_APP_SCRIBE_ENV === 'production') {
  console.log('PRODUCTION CONFIG')
  config = {
    ...config,
    ...prodConfig,
  }
} else if (process.env.VUE_APP_SCRIBE_ENV === 'staging') {
  console.log('STAGING CONFIG')
  config = {
    ...config,
    ...stagingConfig,
  }
} else {
  console.log('DEVELOPMENT CONFIG')
  config = {
    ...config,
    ...devConfig,
  }
}

config.apiUrl = config.apiProtocol + config.apiHost
config.siteUrl = config.siteProtocol + config.siteHost
config.apiEndpoint = config.apiUrl + '/api'
config.endpoints.leaderboard = config.apiEndpoint + '/leaderboard'
config.endpoints.playerProfile = config.apiEndpoint + '/players'
config.endpoints.search = config.endpoints.playerProfile + '/search'
config.endpoints.historicalTopX = config.apiEndpoint + '/'
config.endpoints.delta = config.endpoints.leaderboard + '/delta'
config.endpoints.deltas = config.endpoints.leaderboard + '/deltas'
config.endpoints.websocket =
  (config.apiProtocol === 'http://' ? 'ws://' : 'wss://') +
  config.apiHost +
  '/ws'
config.analytics = {
  enabled: config.isProd,
}

module.exports = config
