<template>
  <div class="usage-wrapper">
    <div v-if="showHint" class="hint-wrapper bottom-corner-borders">
      <div>
        Click the <span v-if="!searchClicked">glowing</span> magnifying glass to
        search ANY player, using their Steam ID, Epic Games ID, PlayFab ID or
        unique Steam name.
      </div>
      <div>Click on a player's name to see their rank progression.</div>
      <div>
        Click on 'Advanced statistics' for in-depth stats on queue times, rank
        distribution and player count by game mode/location/platform
      </div>

      <button class="scribe-button" @click="dismissUsageHint">Got it</button>
    </div>
    <ScribeTooltip class="advanced-statistics">
      <template #content>
        In-depth stats on queue times, rank distribution and player count by
        game mode/location/platform
      </template>
      <a
        href="https://thnitch.grafana.net/public-dashboards/7f4760f1bf354eaf805a2748c18aa636?orgId=1&refresh=5m"
        target="_blank"
        rel="noopener noreferrer"
        :class="{ glow: !advancedStatsClicked }"
        @click="setAdvancedStatsClicked"
      >
        Advanced statistics</a
      >
    </ScribeTooltip>
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useMainStore } from '@/stores/main'
import ScribeTooltip from '@/components/ScribeTooltip'

export default {
  components: { ScribeTooltip },
  computed: {
    ...mapState(useMainStore, [
      'usageHintDismissed',
      'searchClicked',
      'advancedStatsClicked',
    ]),
    showHint() {
      if (this.$route.name == null) return false
      return !this.usageHintDismissed
    },
  },
  methods: {
    ...mapActions(useMainStore, [
      'dismissUsageHint',
      'setAdvancedStatsClicked',
    ]),
  },
}
</script>

<style lang="scss" scoped>
.usage-wrapper {
  max-width: $base-width;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.hint-wrapper {
  max-width: $base-width;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0.5rem 0;

  > div {
    margin: 0.5rem 0;
  }
}

.advanced-statistics {
  margin: 0.2rem 0;
}
</style>
