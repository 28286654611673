import { isEqual } from 'date-fns'
import {
  fetchLeaderboard,
  fetchDelta,
  fetchDeltas,
} from '@/_services/leaderboardService'
import { formatDistanceStrict } from 'date-fns'
import { defineStore } from 'pinia'
import { QUEUE_TYPE } from '@/_helpers/enums'
import { unref } from 'vue'

const humanReadableDuration = (x) =>
  formatDistanceStrict(new Date(0), new Date(x * 1000))

const LEADERBOARD_STALENESS_WAIT = 60000

export const useDuelLeaderboardStore = useLeaderboardStore(QUEUE_TYPE.DUEL)
export const useTeamfightLeaderboardStore = useLeaderboardStore(
  QUEUE_TYPE.TEAMFIGHT
)

function useLeaderboardStore(queueType) {
  return defineStore(queueType.toLowerCase(), {
    persist: true,
    state() {
      return {
        leaderboard: null,
        leaderboardIsUpdating: false,
        lastUpdateAttempt: null,
        lastUpdateSuccess: true,
        deltaIsUpdating: false,
        currentDelta: null,
        selectedDelta: 0,
        deltas: {},
        defaultDeltaDuration: 0,
        availableDeltas: [],
      }
    },
    actions: {
      async updateLeaderboard(allowCache = true) {
        this.leaderboardIsUpdating = true
        const lastTimestamp =
          allowCache && this.leaderboard ? this.leaderboard.timestamp : null
        if (
          lastTimestamp &&
          Math.abs(new Date() - new Date(lastTimestamp)) <
            LEADERBOARD_STALENESS_WAIT
        ) {
          this.lastUpdateAttempt = +new Date()
          this.leaderboardIsUpdating = false
          return
        }

        fetchLeaderboard(queueType, this.selectedDelta, lastTimestamp)
          .then((data) => {
            if (data != 304) {
              const leaderboard = data
              this.deltas = {}

              if (leaderboard.defaultDelta) {
                this.defaultDeltaDuration = leaderboard.defaultDelta.duration
                this.deltas[leaderboard.defaultDelta.duration] =
                  leaderboard.defaultDelta

                if (
                  this.selectedDelta &&
                  this.availableDeltas.findIndex(
                    (delta) => delta == this.selectedDelta
                  ) ==
                    this.availableDeltas.length - 1
                ) {
                  const newDelta =
                    leaderboard.availableDeltas[
                      leaderboard.availableDeltas.length - 1
                    ]
                  this.selectedDelta = newDelta
                } else if (
                  !this.selectedDelta ||
                  !leaderboard.availableDeltas.includes(this.selectedDelta)
                ) {
                  this.selectedDelta = leaderboard.defaultDelta.duration
                }

                delete leaderboard.defaultDelta
              }

              this.availableDeltas = leaderboard.availableDeltas
              delete leaderboard.availableDeltas

              this.lastUpdateAttempt = +new Date()
              this.lastUpdateSuccess = true
              this.leaderboard = leaderboard
              this.updateDelta(this.selectedDelta)
            } else {
              this.lastUpdateSuccess = true
            }
            this.leaderboardIsUpdating = false
          })
          .catch(() => {
            this.leaderboardIsUpdating = false
            this.lastUpdateSuccess = false
          })
      },
      async setSelectedDelta(duration) {
        this.selectedDelta = duration
      },
      async updateDelta(duration = null) {
        if (duration != null) {
          this.selectedDelta = duration
        }
        if (this.deltas[this.selectedDelta]) {
          this.currentDelta = this.deltas[this.selectedDelta]
        } else {
          this.deltaIsUpdating = true
          fetchDelta(
            queueType,
            this.leaderboard.timestamp,
            this.selectedDelta
          ).then(
            (delta) => {
              if (!this.leaderboard.timestampDate)
                this.leaderboard.timestampDate = new Date(
                  this.leaderboard.timestamp
                )

              if (
                isEqual(this.leaderboard.timestampDate, new Date(delta.endTime))
              ) {
                this.deltas[delta.duration] = delta
                if (this.selectedDelta == delta.duration) {
                  this.currentDelta = delta
                }
              } else {
                console.log('new delta is stale')
              }
              this.deltaIsUpdating = false
            },
            (error) => {
              if (error == 410 || error == 404) this.updateLeaderboard(false)
              this.deltaIsUpdating = false
            }
          )
        }
      },
      async ensureAvailableDeltasPresent() {
        if (unref(this.availableDeltas.length) != 0) return
        this.deltaIsUpdating = true
        fetchDeltas(queueType).then(
          (response) => {
            this.availableDeltas = response.availableDeltas
            if (!this.selectedDelta) this.selectedDelta = response.defaultDelta
            this.currentDelta = this.deltas[this.selectedDelta]
            this.deltaIsUpdating = false
          },
          () => {
            this.deltaIsUpdating = false
          }
        )
      },

      async resetLeaderboardState() {
        this.$reset()
      },
    },
    getters: {
      availableDeltaLabels: (state) => {
        return state.availableDeltas.map(humanReadableDuration)
      },
      labelByDelta: (state) => {
        if (state.availableDeltaLabels)
          return state.availableDeltas.reduce((acc, duration, index) => {
            acc[duration] = state.availableDeltaLabels[index]
            return acc
          }, {})
      },
    },
  })
}
