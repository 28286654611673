import { QUEUE_TYPE, LEADERBOARD_TYPE } from '@/_helpers/enums'
import { defineStore } from 'pinia'

export const useMainStore = defineStore('main', {
  persist: true,
  state() {
    return {
      usageHintDismissed: false,
      searchClicked: false,
      historyClicked: false,
      advancedStatsClicked: false,
      selectedQueueType: QUEUE_TYPE.DUEL,
      selectedLeaderboardType: LEADERBOARD_TYPE.CURRENT_TOP_100,
      showCasualStats: true,
    }
  },
  actions: {
    async dismissUsageHint() {
      this.usageHintDismissed = true
    },
    async setSearchClicked() {
      this.searchClicked = true
    },
    async setAdvancedStatsClicked() {
      this.advancedStatsClicked = true
    },
    async setHistoryClicked() {
      this.historyClicked = true
    },
    async setShowCasualStats(doShow) {
      this.showCasualStats = doShow
    },
    async setSelectedQueueType(selectedQueueType) {
      this.selectedQueueType = selectedQueueType
    },
    async setSelectedLeaderboardType(selectedLeaderboardType) {
      this.selectedLeaderboardType = selectedLeaderboardType
    },
    async setLeaderboardContext(leaderboardContext) {
      this.selectedLeaderboardType = leaderboardContext.type
      this.selectedQueueType = leaderboardContext.queueType
    },
    async resetState() {
      this.$reset()
    },
  },
})
