import { LEADERBOARD_TYPE, QUEUE_TYPE } from '@/_helpers/enums'
import {
  fetchAllTimeLeaderboard,
  fetchPeakMmrLeaderboard,
} from '@/_services/leaderboardService'
import { defineStore } from 'pinia'

const LEADERBOARD_STALENESS_WAIT = 300000

export const useDuelAllTimeLeaderboardStore = useUnofficialLeaderboardStore(
  `${QUEUE_TYPE.DUEL}${LEADERBOARD_TYPE.ALL_TIME}`,
  (lastTimestamp) => fetchAllTimeLeaderboard(QUEUE_TYPE.DUEL, lastTimestamp)
)
export const useTeamfightAllTimeLeaderboardStore =
  useUnofficialLeaderboardStore(
    `${QUEUE_TYPE.TEAMFIGHT}${LEADERBOARD_TYPE.ALL_TIME}`,
    (lastTimestamp) =>
      fetchAllTimeLeaderboard(QUEUE_TYPE.TEAMFIGHT, lastTimestamp)
  )

export const useDuelPeakMmrLeaderboardStore = useUnofficialLeaderboardStore(
  `${QUEUE_TYPE.DUEL}${LEADERBOARD_TYPE.PEAK_MMR}`,
  (lastTimestamp) => fetchPeakMmrLeaderboard(QUEUE_TYPE.DUEL, lastTimestamp)
)
export const useTeamfightPeakMmrLeaderboardStore =
  useUnofficialLeaderboardStore(
    `${QUEUE_TYPE.TEAMFIGHT}${LEADERBOARD_TYPE.PEAK_MMR}`,
    (lastTimestamp) =>
      fetchPeakMmrLeaderboard(QUEUE_TYPE.TEAMFIGHT, lastTimestamp)
  )

function useUnofficialLeaderboardStore(
  queueAndLeaderboardType,
  fetchUnofficialLeaderboard
) {
  return defineStore(queueAndLeaderboardType.toLowerCase(), {
    persist: true,
    state() {
      return {
        leaderboard: null,
        leaderboardIsUpdating: false,
        lastUpdateAttempt: null,
        lastUpdateSuccess: true,
      }
    },
    actions: {
      async updateLeaderboard(allowCache = true) {
        this.leaderboardIsUpdating = true
        const lastTimestamp =
          allowCache && this.leaderboard ? this.leaderboard.timestamp : null
        if (
          lastTimestamp &&
          Math.abs(new Date() - new Date(lastTimestamp)) <
            LEADERBOARD_STALENESS_WAIT
        ) {
          this.lastUpdateAttempt = +new Date()
          this.leaderboardIsUpdating = false
          return
        }

        fetchUnofficialLeaderboard(lastTimestamp)
          .then((data) => {
            if (data == 304) {
              this.lastUpdateSuccess = true
            } else {
              this.lastUpdateAttempt = +new Date()
              this.lastUpdateSuccess = true
              this.leaderboard = data
            }
            this.leaderboardIsUpdating = false
          })
          .catch(() => {
            this.leaderboardIsUpdating = false
            this.lastUpdateSuccess = false
          })
      },
      async resetLeaderboardState() {
        this.$reset()
      },
    },
  })
}
