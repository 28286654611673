import { format, formatDistance, formatDistanceToNow } from 'date-fns'
import { enGB } from 'date-fns/locale'

export default {
  format: function (date, formatStr = 'Pp:ss', options = {}) {
    return format(date, formatStr, {
      locale: enGB,
      ...options,
    })
  },
  formatDistance: function (date, baseDate, options = {}) {
    return formatDistance(date, baseDate, {
      locale: enGB,
      ...options,
    })
  },
  formatDistanceToNow: function (date, options = {}) {
    return formatDistanceToNow(date, {
      locale: enGB,
      ...options,
    })
  },
}
