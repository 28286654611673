<template>
  <Popper
    class="scribe-tooltip"
    v-bind="$attrs"
    hover
    :interactive="false"
    placement="top"
    offset-distance="8"
  >
    <slot />
    <template #content="props">
      <slot name="content" v-bind="props" />
    </template>
  </Popper>
</template>

<script>
import Popper from 'vue3-popper'

export default {
  name: 'ScribeTooltip',
  components: {
    Popper,
  },
}
</script>
<style lang="scss" scoped>
:deep(.popper) {
  max-width: 20rem;
}
</style>
