<template>
  <div class="flex-vertical">
    <div v-if="!isProd">
      <div class="controls-wrapper">
        <div class="controls">
          <div class="scribe-button-group">
            <button class="scribe-button" @click="resetState">rst state</button>
            <button class="scribe-button" @click="resetLeaderboardState">
              rst ldbrd
            </button>
            <button class="scribe-button" @click="forceUpdate">
              force update
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isUpdating" class="loading-indicator">Updating...</div>
    <div v-if="!isOfficial" class="unofficial-header">
      <div>
        Unofficial
        <span v-if="isAllTimeLeaderboard"> (hidden ranks)</span>
        <span v-if="isPeakMmrLeaderboard"> (peak MMR)</span>
        <button
          class="scribe-button explanation-button"
          @click="
            () => (showUnofficialExplanation = !showUnofficialExplanation)
          "
        >
          <span v-show="!showUnofficialExplanation">?</span>
          <vue-feather type="x" v-show="showUnofficialExplanation" size="15" />
        </button>
      </div>

      <CollapseTransition :duration="150">
        <div
          v-if="isAllTimeLeaderboard"
          v-show="showUnofficialExplanation"
          class="unofficial-explanation"
        >
          Shows all players, including those who disappeared from the official
          leaderboard. Usually that means their rank has been hidden after 7
          days of inactivity, but it can also be due to losing or MMR resets.
        </div>
      </CollapseTransition>
      <CollapseTransition :duration="150">
        <div
          v-if="isPeakMmrLeaderboard"
          v-show="showUnofficialExplanation"
          class="unofficial-explanation"
        >
          Shows the top MMR ever achieved by a player.
        </div>
      </CollapseTransition>
    </div>
    <div
      class="online-players-info"
      v-if="
        leaderboard != null &&
        showOnlinePlayerCount &&
        (leaderboard.playerCountInGame || leaderboard.playerCountInQueue)
      "
    >
      <span
        v-if="leaderboard.playerCountInGame && leaderboard.playerCountInQueue"
      >
        Currently
        <span class="online-player-count">{{
          leaderboard.playerCountInGame
        }}</span>
        players in {{ queueType }} games and
        <span class="online-player-count">{{
          leaderboard.playerCountInQueue
        }}</span>
        in queue
      </span>
      <span
        v-else-if="
          leaderboard.playerCountInGame && !leaderboard.playerCountInQueue
        "
      >
        Currently
        <span class="online-player-count">{{
          leaderboard.playerCountInGame
        }}</span>
        players in {{ queueType }} games
      </span>
      <span
        v-else-if="
          !leaderboard.playerCountInGame && leaderboard.playerCountInQueue
        "
      >
        Currently
        <span class="online-player-count">{{
          leaderboard.playerCountInQueue
        }}</span>
        players in queue for {{ queueType }} games
      </span>
    </div>
    <transition-group
      v-if="leaderboard != null"
      class="flex-vertical"
      name="flip-list"
      tag="div"
    >
      <LeaderboardEntry
        v-for="entry in leaderboard.entries"
        :key="entry.PlayFabId"
        :entry="entry"
        :queueType="queueType"
        :leaderboardType="leaderboardType"
        :deltaEnabled="deltaEnabled"
        :timestampEnabled="timestampEnabled"
      />
    </transition-group>
    <div v-if="!lastUpdateSuccess" class="error-message">Update failed!</div>
    <div v-if="this.leaderboard != null" class="time-info">
      <div class="time-info-labels">
        <div>Last update:</div>
        <div>Last change:</div>
      </div>
      <div class="time-info-values">
        <ScribeTooltip :content="lastUpdateAttemptFormatted">
          {{ lastUpdateAttemptToNow }}
        </ScribeTooltip>
        <ScribeTooltip :content="lastChangeFormatted">
          {{ lastChangeToNow }}
        </ScribeTooltip>
      </div>
    </div>
  </div>
</template>

<script>
import LeaderboardEntry from '@/components/LeaderboardEntry'
import DateFnsWrapper from '@/_helpers/dateFnsWrapper.js'
import ScribeTooltip from '@/components/ScribeTooltip.vue'
import config from '@/config/config.js'
import { LEADERBOARD_TYPE } from '@/_helpers/enums'
import { useMainStore } from '@/stores/main'
import { computed, onActivated, onBeforeUnmount, onDeactivated, ref } from 'vue'
import { useStoreMap } from '@/stores'
import CollapseTransition from '@ivanv/vue-collapse-transition/src/CollapseTransition.vue'

const LEADERBOARD_UPDATE_CHECK_INTERVAL = 60000

export default {
  components: { LeaderboardEntry, ScribeTooltip, CollapseTransition },
  props: {
    queueType: {
      type: String,
      required: true,
    },
    leaderboardType: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const isProd = config.isProd
    const showUnofficialExplanation = ref(false)
    const lastUpdateTime = ref(+new Date())

    const mainStore = useMainStore()
    const selectedQueueType = computed(() => mainStore.selectedQueueType)
    const selectedLeaderboardType = computed(
      () => mainStore.selectedLeaderboardType
    )
    const isSelected = computed(() => {
      return (
        selectedQueueType.value == props.queueType &&
        selectedLeaderboardType.value == props.leaderboardType
      )
    })

    const leaderboardStore =
      useStoreMap[`${props.queueType}${props.leaderboardType}`]()
    const leaderboard = computed(() => leaderboardStore.leaderboard)
    const leaderboardIsUpdating = computed(
      () => leaderboardStore.leaderboardIsUpdating
    )
    const deltaIsUpdating = computed(() => leaderboardStore.deltaIsUpdating)
    const lastUpdateAttempt = computed(() => leaderboardStore.lastUpdateAttempt)
    const lastUpdateSuccess = computed(() => leaderboardStore.lastUpdateSuccess)

    const checkUpdate = () => {
      if (document.hasFocus() && isSelected.value) {
        lastUpdateTime.value = +new Date()
        leaderboardStore.updateLeaderboard()
      }
    }

    const forceUpdate = () => {
      leaderboardStore.updateLeaderboard(false)
    }

    const resetState = () => {
      mainStore.resetState(false)
    }

    const resetLeaderboardState = () => {
      leaderboardStore.resetLeaderboardState()
    }

    const intervalId = setInterval(
      checkUpdate,
      LEADERBOARD_UPDATE_CHECK_INTERVAL
    )
    window.addEventListener('focus', checkUpdate, false)

    onBeforeUnmount(() => {
      if (intervalId) clearInterval(intervalId)
      window.removeEventListener('focus', checkUpdate)
    })

    let unsubscribe = () => {}
    onActivated(() => {
      checkUpdate()

      if (isOfficial) {
        unsubscribe = leaderboardStore.$onAction(({ name, store, after }) => {
          after(() => {
            if (name === 'setSelectedDelta') store.updateDelta()
          })
        })
      }
    })

    onDeactivated(() => {
      unsubscribe()
    })

    const isOfficial = props.leaderboardType == LEADERBOARD_TYPE.CURRENT_TOP_100
    const isAllTimeLeaderboard =
      props.leaderboardType == LEADERBOARD_TYPE.ALL_TIME
    const isPeakMmrLeaderboard =
      props.leaderboardType == LEADERBOARD_TYPE.PEAK_MMR
    const deltaEnabled =
      props.leaderboardType == LEADERBOARD_TYPE.CURRENT_TOP_100
    const timestampEnabled =
      props.leaderboardType != LEADERBOARD_TYPE.CURRENT_TOP_100

    const isUpdating = computed(() => {
      return leaderboardIsUpdating.value || deltaIsUpdating.value
    })
    const lastChangeDate = computed(() => {
      return leaderboard.value ? new Date(leaderboard.value.lastChange) : null
    })
    const lastChangeFormatted = computed(() => {
      if (!lastChangeDate.value) return '-'
      return DateFnsWrapper.format(lastChangeDate.value)
    })
    const lastChangeToNow = computed(() => {
      if (!lastChangeDate.value) return '-'
      return DateFnsWrapper.formatDistanceToNow(lastChangeDate.value, {
        addSuffix: true,
        includeSeconds: true,
      })
    })
    const lastUpdateAttemptDate = computed(() => {
      return lastUpdateAttempt.value ? new Date(lastUpdateAttempt.value) : null
    })
    const lastUpdateAttemptFormatted = computed(() => {
      if (!lastUpdateAttemptDate.value) return '-'
      return DateFnsWrapper.format(lastUpdateAttemptDate.value)
    })
    const lastUpdateAttemptToNow = computed(() => {
      if (!lastUpdateAttemptDate.value) return '-'
      return DateFnsWrapper.formatDistanceToNow(lastUpdateAttemptDate.value, {
        addSuffix: true,
        includeSeconds: true,
      })
    })

    const showOnlinePlayerCount = computed(() => {
      if (!lastUpdateSuccess.value || !lastUpdateTime.value) return false
      return lastUpdateTime.value + 120000 > +new Date()
    })

    return {
      intervalId,
      isProd,
      showUnofficialExplanation,
      leaderboard,
      leaderboardIsUpdating,
      lastUpdateTime,
      deltaIsUpdating,
      lastUpdateAttempt,
      lastUpdateSuccess,
      isOfficial,
      isAllTimeLeaderboard,
      isPeakMmrLeaderboard,
      deltaEnabled,
      timestampEnabled,
      isUpdating,
      lastChangeFormatted,
      lastChangeToNow,
      lastUpdateAttemptFormatted,
      lastUpdateAttemptToNow,
      showOnlinePlayerCount,
      forceUpdate,
      resetState,
      resetLeaderboardState,
    }
  },
}
</script>

<style scoped lang="scss">
$rfs-base-value: 0.8rem;
@import '~rfs/scss';

.loading-indicator {
  position: fixed;
  display: flex;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 5.5rem;
  height: 2.5rem;
  justify-content: center;
  align-items: center;
  cursor: default;
  @include scribe-box;
}

.time-info {
  @include font-size(1rem);
  @include flex-horizontal;

  & > div {
    margin: 0.1rem;
  }
}

.time-info-labels {
  text-align: right;
}

.time-info-values {
  > div {
    display: inline;
  }
}

.flip-list-move {
  transition: transform 2s;
}

.unofficial-header {
  @include font-size(1.5rem);
  text-align: center;
  max-width: $base-width;
}

.unofficial-explanation {
  @include font-size(1.2rem);
  text-align: center;
  margin: 0.5rem 0;
}

.explanation-button {
  width: 2.5rem;
}

.online-player-count {
  color: #b89836;
}

.online-players-info {
  text-align: center;
}
</style>
