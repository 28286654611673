<template>
  <div class="flex-vertical">
    <Leaderboard
      :leaderboardType="leaderboardType"
      :queueType="queueType"
      class="leaderboard"
    />
  </div>
</template>

<script>
import Leaderboard from '@/components/ScribeLeaderboard.vue'
import { QUEUE_TYPE, LEADERBOARD_TYPE } from '@/_helpers/enums'

export default {
  name: 'DuelPeakMmrLeaderboardPage',
  components: {
    Leaderboard,
  },
  data() {
    return {
      leaderboardType: LEADERBOARD_TYPE.PEAK_MMR,
      queueType: QUEUE_TYPE.DUEL,
    }
  },
}
</script>

<style scoped lang="scss">
.leaderboard {
  width: 100%;
  min-width: 0;
}
</style>
