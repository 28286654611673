import { defineStore } from 'pinia'
import { LEADERBOARD_TYPE } from '@/_helpers/enums'
import { useMainStore } from '@/stores/main'
import { useStoreMap } from '@/stores'

export const useSelectedLeaderboard = defineStore('selectedLeaderboard', {
  actions: {
    async updateLeaderboard(allowCache = true) {
      const leaderboardStore = useStoreMap[this.leaderboardName]()
      leaderboardStore.updateLeaderboard(allowCache)
    },
    async setSelectedDelta(duration = null) {
      const officialLeaderboardStore =
        useStoreMap[`${this.selectedQueueType}`]()
      officialLeaderboardStore.setSelectedDelta(duration)
    },
    async updateDelta(duration = null) {
      const officialLeaderboardStore =
        useStoreMap[`${this.selectedQueueType}`]()
      officialLeaderboardStore.updateDelta(duration)
    },
    async ensureAvailableDeltasPresent() {
      const officialLeaderboardStore =
        useStoreMap[`${this.selectedQueueType}`]()
      officialLeaderboardStore.ensureAvailableDeltasPresent()
    },
    async resetLeaderboardState() {
      const leaderboardStore = useStoreMap[this.leaderboardName]()
      leaderboardStore.resetLeaderboardState()
    },
  },
  getters: {
    leaderboard() {
      const leaderboardStore = useStoreMap[this.leaderboardName]()
      return leaderboardStore.leaderboard
    },
    leaderboardIsUpdating() {
      const leaderboardStore = useStoreMap[this.leaderboardName]()
      return leaderboardStore.leaderboardIsUpdating
    },
    lastUpdateAttempt() {
      const leaderboardStore = useStoreMap[this.leaderboardName]()
      return leaderboardStore.lastUpdateAttempt
    },
    lastUpdateSuccess() {
      const leaderboardStore = useStoreMap[this.leaderboardName]()
      return leaderboardStore.lastUpdateSuccess
    },
    deltaIsUpdating() {
      const leaderboardStore = useStoreMap[this.officialLeaderboardName]()
      return leaderboardStore.deltaIsUpdating
    },
    currentDelta() {
      const leaderboardStore = useStoreMap[this.officialLeaderboardName]()
      return leaderboardStore.currentDelta
    },
    selectedDelta() {
      const leaderboardStore = useStoreMap[this.officialLeaderboardName]()
      return leaderboardStore.selectedDelta
    },
    deltas() {
      const leaderboardStore = useStoreMap[this.officialLeaderboardName]()
      return leaderboardStore.deltas
    },
    defaultDeltaDuration() {
      const leaderboardStore = useStoreMap[this.officialLeaderboardName]()
      return leaderboardStore.defaultDeltaDuration
    },
    availableDeltas() {
      const leaderboardStore = useStoreMap[this.officialLeaderboardName]()
      return leaderboardStore.availableDeltas
    },
    availableDeltaLabels() {
      const leaderboardStore = useStoreMap[this.officialLeaderboardName]()
      return leaderboardStore.availableDeltaLabels
    },
    selectedQueueType() {
      const mainStore = useMainStore()
      return mainStore.selectedQueueType
    },
    selectedLeaderboardType() {
      const mainStore = useMainStore()
      return mainStore.selectedLeaderboardType
    },
    officialLeaderboardName() {
      return `${this.selectedQueueType}`
    },
    leaderboardName() {
      return `${this.selectedQueueType}${this.selectedLeaderboardType}`
    },
    isOfficial() {
      return this.selectedLeaderboardType === LEADERBOARD_TYPE.CURRENT_TOP_100
    },
  },
})
