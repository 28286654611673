import { notify } from '@kyvg/vue3-notification'

export function handleResponse(response) {
  if (!response) return handleFetchError()
  return response.text().then((text) => {
    try {
      if (!response.ok) {
        const data = text && JSON.parse(text)

        const errorMessage = (data && data.message) || response.statusText
        console.log(errorMessage)
        if (errorMessage) {
          logError(errorMessage)
        } else {
          logError('Something went wrong')
        }

        return Promise.reject(errorMessage)
      }
      return text && JSON.parse(text)
    } catch (error) {
      console.log(error)
      logError('Something went wrong')
      return Promise.reject()
    }
  })
}

export function handleFetchError(error) {
  console.log(error)
  logError('Either you have no internet or the Mordhau Scribe server is down')
  return Promise.reject()
}

export function logError(error) {
  console.error(error)

  notify({
    text: error,
    type: 'error',
    ignoreDuplicates: true,
  })
}
