<template>
  <div class="flex-horizontal footer-wrapper">
    <div class="flex-horizontal footer">
      <a
        href="https://gitlab.com/thnitch/mordhau-scribe"
        target="_blank"
        rel="noopener noreferrer"
      >
        Source code
      </a>
      <div class="dot-separator">&middot;</div>
      <router-link
        to="/history"
        :class="{ glow: !historyClicked }"
        @click="setHistoryClicked"
        >History</router-link
      >
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useMainStore } from '@/stores/main'
export default {
  computed: {
    ...mapState(useMainStore, ['historyClicked']),
  },
  methods: {
    ...mapActions(useMainStore, ['setHistoryClicked']),
  },
}
</script>

<style lang="scss" scoped>
.footer-wrapper {
  margin-top: auto;
}

.footer {
  padding: 0.5rem;
  background-color: darken($color: $background-color, $amount: 2);
}

.dot-separator {
  margin: 0 0.2rem;
}
</style>
