<template>
  <div class="not-found-message flex-vertical">
    <div class="http-code">Not found</div>
    <div>Return to the dung-covered peasant convention?</div>
    <button
      class="scribe-button scribe-button-big"
      @click.prevent="handleBack(`/${selectedQueueType}`)"
    >
      Back
    </button>
  </div>
</template>

<script>
import RouterBack from '@/mixins/RouterBack'
import { mapState } from 'pinia'
import { useMainStore } from '@/stores/main'
export default {
  mixins: [RouterBack],
  computed: {
    ...mapState(useMainStore, ['selectedQueueType']),
  },
}
</script>

<style lang="scss" scoped>
.not-found-message {
  @include font-size(1.5rem);
  height: 100%;
  text-align: center;
  padding-bottom: $controls-height;
}

.not-found-message > * {
  margin: 0.5rem 0;
}
</style>
