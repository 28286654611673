<template>
  <div class="controls-wrapper delta-controls-wrapper">
    <div class="controls">
      <div class="scribe-button-group delta-buttons" v-show="isEnabled">
        <button
          class="scribe-button delta-button"
          :class="{ active: delta === selectedDelta }"
          v-for="(delta, index) in availableDeltas"
          :key="delta"
          @click="select(delta)"
        >
          <vue-feather
            type="chevron-right"
            v-if="index == availableDeltas.length - 1"
            size="14"
          />{{ availableDeltaLabels[index] }}
        </button>
      </div>
      <div v-show="!isEnabled" class="no-delta-hint">
        Deltas not available for unofficial leaderboards
      </div>
    </div>
  </div>
</template>

<script setup>
import { useSelectedLeaderboard } from '@/stores/selectedLeaderboard'
import { computed, watch } from 'vue'

defineProps({
  isEnabled: {
    type: Boolean,
    default: true,
  },
})

const selectedLeaderboardStore = useSelectedLeaderboard()
const selectedDelta = computed(() => selectedLeaderboardStore.selectedDelta)
const availableDeltas = computed(() => selectedLeaderboardStore.availableDeltas)
const availableDeltaLabels = computed(
  () => selectedLeaderboardStore.availableDeltaLabels
)

watch(
  () => selectedLeaderboardStore.selectedQueueType,
  selectedLeaderboardStore.ensureAvailableDeltasPresent
)

selectedLeaderboardStore.ensureAvailableDeltasPresent()

const select = (delta) => {
  selectedLeaderboardStore.setSelectedDelta(delta)
}
</script>

<style scoped lang="scss">
.delta-controls-wrapper {
  flex-grow: 1;
}

.delta-buttons {
  flex-grow: 1;
}

.delta-button {
  white-space: nowrap;
  overflow: hidden;
  padding: 0.25rem 0.2rem;
  min-width: 2rem;
  text-overflow: ellipsis;
  outline: none;
}

.no-delta-hint {
  text-align: center;
}
</style>
