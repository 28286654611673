import config from '@/config/config'
import { handleResponse, handleFetchError } from '@/_helpers/error-handling.js'

const API_ERRORS = [404, 409]

export async function fetchPlayerProfile(identifier) {
  const requestOptions = {
    method: 'GET',
  }

  return fetch(
    `${config.endpoints.playerProfile}/${identifier}`,
    requestOptions
  ).then((response) => {
    if (API_ERRORS.includes(response.status))
      return Promise.reject(response.status)
    return handleResponse(response)
  }, handleFetchError)
}

export async function searchPlayer(name) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ name }),
  }

  return fetch(`${config.endpoints.search}`, requestOptions).then(
    (response) => {
      if (response.status == 404) return Promise.reject(response.status)
      return handleResponse(response)
    },
    handleFetchError
  )
}
