<template>
  <div class="controls-wrapper">
    <div class="controls">
      <form class="search-controls" @submit.prevent="onSubmit">
        <input
          v-model="searchInput"
          id="search-input"
          class="scribe-input search-input"
          type="search"
          placeholder="Search player"
          :disabled="searchInProgress"
          @paste="onPaste"
        />
        <button
          class="scribe-button submit-search-button"
          :class="{ 'search-in-progress': searchInProgress }"
          type="submit"
          :disabled="searchInProgress"
        >
          Search
        </button>
        <!-- TODO: Document all search options https://www.epicgames.com/help/en-US/epic-accounts-c5719348850459/general-support-c5719341353627/what-is-an-epic-account-id-and-where-can-i-find-it-a5720265298075?sessionInvalidated=true -->
      </form>
    </div>
  </div>
</template>

<script setup>
import router from '@/router'
import { searchPlayer } from '@/_services/playerProfileService'
import { notify } from '@kyvg/vue3-notification'
import { ref } from 'vue'
import { useRoute } from 'vue-router'

const emit = defineEmits(['search-success'])
const route = useRoute()
const searchInput = ref('')
const failedSearches = ref(new Set())
const searchInProgress = ref(false)

const onPaste = (event) => {
  let pastedString = event.clipboardData.getData('text')
  if (!pastedString) return
  pastedString = pastedString.trim()

  const parsedId = parseId(pastedString)
  if (parsedId) {
    searchInput.value = parsedId
    search(true)
  }
}

const parseId = (stringToParse) => {
  const idFromLookupPlayer = parseIdFromLookupPlayerCommand(stringToParse)
  if (idFromLookupPlayer) return idFromLookupPlayer

  const idFromSteamCommunity = parseIdFromSteamCommunity(stringToParse)
  if (idFromSteamCommunity) return idFromSteamCommunity

  const idFromEpicGames = parseIdFromEpicgames(stringToParse)
  if (idFromEpicGames) return idFromEpicGames

  const playFabId = parsePlayFabId(stringToParse)
  if (playFabId) return playFabId

  const steamId = parseSteamId(stringToParse)
  if (steamId) return steamId

  const epicGamesId = parseEpicGamesId(stringToParse)
  if (epicGamesId) return epicGamesId
}

const onSubmit = () => {
  search()
}

const search = (skipIdParsing = false) => {
  if (searchInput.value === '') {
    showToast(
      "Enter a player's Steam ID, Epic Games ID, PlayFab ID or unique Steam name"
    )
    return
  }

  searchInProgress.value = true

  if (!skipIdParsing) {
    const parsedId = parseId(searchInput.value)
    if (parsedId) searchInput.value = parsedId
  }

  if (!failedSearches.value.has(searchInput.value)) {
    searchPlayer(searchInput.value).then(
      (response) => {
        searchInput.value = ''
        emit('search-success')

        if (
          route.name !== 'PlayerProfilePage' ||
          route.params.identifier !== response.playFabId
        ) {
          router.push(`/player/${response.playFabId}`)
        }
        searchInProgress.value = false
      },
      (error) => {
        if (error == 404) {
          failedSearches.value.add(searchInput.value)
          showToast()
        }
        searchInProgress.value = false
      }
    )
  } else {
    setTimeout(() => {
      showToast()
      searchInProgress.value = false
    }, 400)
  }
}
const showToast = (
  message = "Player not found (enter a player's unique platform (Steam) name, Steam ID, Epic Games ID or PlayFab ID)"
) => {
  notify({
    text: message,
    type: 'warn',
    duration: 6000,
  })
}

const playerLookupRegex =
  /^PlayFabID: ([0-9A-Z]+), EntityID: [0-9A-Z]+, Platform: ([0-9A-Za-z]+), PlatformAccountID: ([0-9A-Za-z]+), Name: .*$/i
const steamCommunityRegex =
  /^(?:https?:\/\/)?steamcommunity\.com\/(?:(?:id\/([0-9a-zA-Z-_]+))|(?:profiles\/([0-9]{17}))).*$/i
const epicGamesRegex =
  /^(?:https?:\/\/)?(?:launcher\.)?store\.epicgames\.com(?:\/[a-zA-Z-]+)?\/u\/([a-z0-9]{32}).*$/i
const playFabIdRegex = /^([0-9A-F]{14,16})$/i
const steamIdRegex = /^([0-9]{17})$/i
const steamGamesIdRegex = /^([0-9a-f]{32})$/i

const parseIdFromLookupPlayerCommand = (string) => {
  const match = string.match(playerLookupRegex)
  if (match) return match[3]
  else return false
}

const parseIdFromSteamCommunity = (string) => {
  const match = string.match(steamCommunityRegex)
  if (match) return match[1] || match[2]
  else return false
}

const parseIdFromEpicgames = (string) => {
  const match = string.match(epicGamesRegex)
  if (match) return match[1]
  else return false
}

const parsePlayFabId = (string) => {
  const match = string.match(playFabIdRegex)
  if (match) return match[1]
  else return false
}

const parseSteamId = (string) => {
  const match = string.match(steamIdRegex)
  if (match) return match[1]
  else return false
}

const parseEpicGamesId = (string) => {
  const match = string.match(steamGamesIdRegex)
  if (match) return match[1]
  else return false
}
</script>

<style lang="scss" scoped>
.search-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  flex-grow: 1;
  height: 100%;
}

.submit-search-button {
  margin-left: 0.3rem;

  &.search-in-progress {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    transform: scale(1);
    animation: pulse 2s infinite;
  }
}

.search-input {
  flex-grow: 1;
  width: 0;
}
</style>
