<template>
  <div class="controls-wrapper settings-wrapper">
    <div class="controls">
      <div class="scribe-button-group settings-buttons">
        <button
          class="scribe-button settings-button"
          :class="{
            active: selectedLeaderboardType === leaderboardType.CURRENT_TOP_100,
          }"
          :disabled="
            selectedLeaderboardType === leaderboardType.CURRENT_TOP_100
          "
          @click="() => select(leaderboardType.CURRENT_TOP_100)"
        >
          Official
        </button>
        <button
          class="scribe-button settings-button"
          :class="{
            active: selectedLeaderboardType === leaderboardType.ALL_TIME,
          }"
          :disabled="selectedLeaderboardType === leaderboardType.ALL_TIME"
          @click="() => select(leaderboardType.ALL_TIME)"
        >
          Hidden ranks
        </button>
        <button
          class="scribe-button settings-button"
          :class="{
            active: selectedLeaderboardType === leaderboardType.PEAK_MMR,
          }"
          :disabled="selectedLeaderboardType === leaderboardType.PEAK_MMR"
          @click="() => select(leaderboardType.PEAK_MMR)"
        >
          Peak MMR
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { LEADERBOARD_TYPE } from '@/_helpers/enums'
import { useMainStore } from '@/stores/main'

export default {
  data() {
    return {
      leaderboardType: LEADERBOARD_TYPE,
    }
  },
  computed: {
    ...mapState(useMainStore, ['selectedQueueType', 'selectedLeaderboardType']),
  },
  methods: {
    ...mapActions(useMainStore, ['setSelectedLeaderboardType']),
    select(leaderboardType) {
      this.setSelectedLeaderboardType(leaderboardType)
      this.$router.push(
        `/${this.selectedQueueType}${this.selectedLeaderboardType}`
      )
      this.$emit('done', leaderboardType)
    },
  },
}
</script>

<style scoped lang="scss">
.settings-wrapper {
  flex-grow: 1;
}

.settings-buttons {
  flex-grow: 1;
}

.settings-button {
  white-space: nowrap;
  overflow: hidden;
  padding: 0.25rem 0.2rem;
  min-width: 2rem;
  text-overflow: ellipsis;
  outline: none;

  @media (min-width: $base-width) {
    width: 33.3%;
  }
}
</style>
