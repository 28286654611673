<template>
  <ScribeTooltip :content="mmrTitle">
    <img
      id="rank-image"
      :alt="mmrTitle"
      class="entry-rank-image"
      :srcset="mmrImageSrcSet"
    />
  </ScribeTooltip>
</template>

<script>
import { getDivisionThreshold, getLabelForMmr } from '@/_helpers/rankHelper'
import { computed } from 'vue'
import ScribeTooltip from '@/components/ScribeTooltip.vue'

const availableWidths = [64, 128, 256]
export default {
  components: {
    ScribeTooltip,
  },
  props: {
    defaultSize: {
      type: Number,
      default: 128,
    },
    mmr: {
      required: true,
    },
  },
  setup(props) {
    const divisionThreshold = computed(() => {
      return getDivisionThreshold(props.mmr)
    })
    const mmrImage = computed(() => {
      return `/img/ranks/${props.defaultSize}/${divisionThreshold.value}.webp`
    })
    const mmrImageSrcSet = computed(() => {
      return availableWidths
        .filter((w) => w >= props.defaultSize)
        .map(
          (w, i) =>
            `/img/ranks/${w}/${divisionThreshold.value}.webp ${Math.pow(2, i)}x`
        )
        .join(', ')
    })
    const mmrTitle = computed(() => {
      return getLabelForMmr(props.mmr)
    })

    return {
      mmrImage,
      mmrImageSrcSet,
      mmrTitle,
    }
  },
}
</script>

<style lang="scss" scoped>
.entry-rank-image {
  $image-dimensions: 90%;
  max-width: $image-dimensions;
  max-height: $image-dimensions;
  display: block;
}
</style>
