export const GLOBAL_EVENT = Object.freeze({
  SERVICE_WORKER_UPDATE_READY: 'swUpdateReady',
})

export const QUEUE_TYPE = Object.freeze({
  DUEL: 'duel',
  TEAMFIGHT: 'teamfight',
})

export const QUEUE_TYPE_PATH = Object.freeze({
  [QUEUE_TYPE.DUEL]: 'duel',
  [QUEUE_TYPE.TEAMFIGHT]: 'team',
})

export const LEGACY_QUEUE_TYPE = Object.freeze({
  [QUEUE_TYPE.DUEL]: 'SOLO',
  [QUEUE_TYPE.TEAMFIGHT]: 'TEAM',
})

export const DEFAULT_PLAYER_DELTA = Object.freeze({
  newEntry: false,
  position: 0,
  mmr: 0,
})

export const LEADERBOARD_TYPE = Object.freeze({
  CURRENT_TOP_100: '',
  ALL_TIME: 'AllTime',
  PEAK_MMR: 'PeakMmr',
})

export const LEADERBOARD_TYPES_SUPPORTING_DELTA = Object.freeze([
  LEADERBOARD_TYPE.CURRENT_TOP_100,
])

export const CASUAL_STAT = Object.freeze({
  //
  KILLS: 'Kills',
  ASSISTS: 'Assists',
  //
  BLOCKS: 'Blocks',
  CHAMBERS: 'Chambers',
  //
  KILLS_ONE_MATCH: 'KillsOneMatch',
  ASSISTS_ONE_MATCH: 'AssistsOneMatch',
  KILLS_ONE_LIFE: 'KillsOneLife',
  DAMAGE_TAKEN_ONE_LIFE: 'DamageTakenOneLife',
  KILLS_ONE_ATTACK: 'KillsOneAttack',
  //
  PROJECTILES_DEFLECTED: 'ProjectilesDeflected',
  PROJECTILES_BLOCKED: 'ProjectilesBlocked',
  //
  MELEE_HEAD_HITS: 'MeleeHeadHits',
  MELEE_TORSO_HITS: 'MeleeTorsoHits',
  MELEE_LEG_HITS: 'MeleeLegHits',
  //
  PROJECTILE_HEAD_HITS: 'ProjectileHeadHits',
  PROJECTILE_TORSO_HITS: 'ProjectileTorsoHits',
  PROJECTILE_LEG_HITS: 'ProjectileLegHits',
  //
  DISARMS: 'Disarms',
  BATTLECRIES: 'Battlecries',
  HEADS_DECAPITATED: 'HeadsDecapitated',
  //
  BALLISTA_KILLS: 'BallistaKills',
  BEAR_TRAP_KILLS: 'BearTrapKills',
  KICK_KILLS: 'KickKills',
  FEDER_KILLS: 'FederKills',
  LONGSWORD_KILLS: 'LongswordKills',
  FIRE_KILLS: 'FireKills',
  ICICLE_KILLS: 'IcicleKills',
  CARVING_KNIFE_KILLS: 'CarvingKnifeKills',
  COUCHED_HEAD_KILLS: 'CouchedHeadKills',
  TRAMPLE_KILLS: 'TrampleKills',
  LUTE_KILLS: 'LuteKills',
  MALLET_KILLS: 'MalletKills',
  ROCK_KILLS: 'RockKills',
  FIST_KILLS: 'FistKills',
  POMMEL_KILLS: 'PommelKills',
  LADDER_KILLS: 'LadderKills',
})

export const CASUAL_STAT_NAME = Object.freeze({
  //
  Kills: 'Kills',
  Assists: 'Assists',
  //
  Blocks: 'Blocks',
  Chambers: 'Chambers',
  //
  KillsOneMatch: 'Kills in one match',
  AssistsOneMatch: 'Assists in one match',
  KillsOneLife: 'Kills in one life',
  DamageTakenOneLife: 'Damage taken in one life',
  KillsOneAttack: 'Kills in a single attack',
  //
  ProjectilesDeflected: 'Projectiles deflected',
  ProjectilesBlocked: 'Projectiles blocked',
  //
  MeleeHeadHits: 'Head',
  MeleeTorsoHits: 'Torso',
  MeleeLegHits: 'Leg',
  //
  ProjectileHeadHits: 'Head',
  ProjectileTorsoHits: 'Torso',
  ProjectileLegHits: 'Leg',
  //
  Disarms: 'Disarms',
  Battlecries: 'Battlecries',
  HeadsDecapitated: 'Heads decapitated',
  //
  BallistaKills: 'Ballista',
  BearTrapKills: 'Bear trap',
  KickKills: 'Kick',
  FederKills: 'Training sword',
  LongswordKills: 'Longsword',
  LadderKills: 'On ladder',
  FireKills: 'Fire',
  IcicleKills: 'Icicle',
  CarvingKnifeKills: 'Carving knife',
  CouchedHeadKills: 'Couched head',
  TrampleKills: 'Trample',
  LuteKills: 'Lute',
  MalletKills: 'Mallet',
  RockKills: 'Rock',
  FistKills: 'Fists',
  PommelKills: 'Pommel',
})

export const CASUAL_STATS_KILLS_ASSISTS = Object.freeze([
  CASUAL_STAT.KILLS,
  CASUAL_STAT.ASSISTS,
])

export const CASUAL_STATS_DEFENSE = Object.freeze([
  CASUAL_STAT.BLOCKS,
  CASUAL_STAT.CHAMBERS,
])

const CASUAL_STATS_MISC = Object.freeze([
  CASUAL_STAT.BATTLECRIES,
  CASUAL_STAT.DISARMS,
  CASUAL_STAT.HEADS_DECAPITATED,
])
const CASUAL_STATS_MELEE = Object.freeze([
  CASUAL_STAT.MELEE_HEAD_HITS,
  CASUAL_STAT.MELEE_TORSO_HITS,
  CASUAL_STAT.MELEE_LEG_HITS,
])
const CASUAL_STATS_RANGED = Object.freeze([
  CASUAL_STAT.PROJECTILE_HEAD_HITS,
  CASUAL_STAT.PROJECTILE_TORSO_HITS,
  CASUAL_STAT.PROJECTILE_LEG_HITS,
])

const CASUAL_STATS_LIFETIME = Object.freeze([
  CASUAL_STAT.KILLS_ONE_MATCH,
  CASUAL_STAT.ASSISTS_ONE_MATCH,
  CASUAL_STAT.KILLS_ONE_LIFE,
  CASUAL_STAT.DAMAGE_TAKEN_ONE_LIFE,
  CASUAL_STAT.KILLS_ONE_ATTACK,
])

export const CASUAL_STATS_KILLS_OFFICIAL = Object.freeze([
  CASUAL_STAT.KICK_KILLS,
  CASUAL_STAT.FEDER_KILLS,
  CASUAL_STAT.FIST_KILLS,
  CASUAL_STAT.LADDER_KILLS,
  CASUAL_STAT.POMMEL_KILLS,
  CASUAL_STAT.ROCK_KILLS,
  CASUAL_STAT.BALLISTA_KILLS,
  CASUAL_STAT.FIRE_KILLS,
])

export const CASUAL_STATS_KILLS = Object.freeze([
  ...CASUAL_STATS_KILLS_OFFICIAL,
  CASUAL_STAT.ICICLE_KILLS,
  CASUAL_STAT.BEAR_TRAP_KILLS,
  // CASUAL_STAT.LONGSWORD_KILLS,
  // CASUAL_STAT.CARVING_KNIFE_KILLS,
  CASUAL_STAT.COUCHED_HEAD_KILLS,
  CASUAL_STAT.TRAMPLE_KILLS,
  CASUAL_STAT.LUTE_KILLS,
  // CASUAL_STAT.MALLET_KILLS
])

export const CASUAL_STATS_CATEGORIES = Object.freeze([
  {
    name: 'Kills & Assists',
    stats: CASUAL_STATS_KILLS_ASSISTS,
    calculatePercentage: true,
  },
  {
    name: 'Defense',
    stats: CASUAL_STATS_DEFENSE,
    calculatePercentage: true,
  },
  {
    name: 'Melee Hits',
    stats: CASUAL_STATS_MELEE,
    calculatePercentage: true,
  },
  {
    name: 'Ranged Hits',
    stats: CASUAL_STATS_RANGED,
    calculatePercentage: true,
  },
  {
    name: 'Miscellaneous',
    stats: CASUAL_STATS_MISC,
  },
  {
    name: 'Kills',
    stats: CASUAL_STATS_KILLS_OFFICIAL,
    calculatePercentage: true,
    sort: true,
  },
  {
    name: 'Most',
    stats: CASUAL_STATS_LIFETIME,
  },
])
