<template>
  <div id="app" class="app">
    <notifications
      position="bottom center"
      :duration="3000"
      classes="scribe-toast vue-notification"
    />
    <AppUpdateAlert v-if="appUpdateAlertEnabled" />
    <div class="heading-wrapper">
      <h1 class="heading">
        <router-link class="heading-link" to="/">Mordhau Scribe</router-link>
      </h1>
      <h2 class="sub-heading">Leaderboards and player statistics</h2>
    </div>
    <AppControls id="app-header" @fixed-header-changed="onFixedHeaderChange" />
    <AppNavigation />
    <router-view
      class="router-view"
      v-slot="{ Component }"
      :style="routerViewStyle"
    >
      <keep-alive
        :include="[
          'DuelLeaderboardPage',
          'TeamfightLeaderboardPage',
          'DuelAllTimeLeaderboardPage',
          'TeamfightAllTimeLeaderboardPage',
          'DuelPeakMmrLeaderboardPage',
          'TeamfightPeakMmrLeaderboardPage',
        ]"
      >
        <component :is="Component" />
      </keep-alive>
    </router-view>
    <AppFooter />
  </div>
</template>
<script>
import AppUpdateAlert from '@/components/AppUpdateAlert'
import AppControls from '@/components/AppControls'
import AppNavigation from '@/components/AppNavigation'
import AppFooter from '@/components/AppFooter'
import { useHead } from '@unhead/vue'
import { useWindowSize } from 'vue-window-size'
import { computed, onMounted, ref, watch } from 'vue'

export default {
  components: {
    AppUpdateAlert,
    AppControls,
    AppNavigation,
    AppFooter,
  },
  setup() {
    useHead({
      title: `${process.env.VUE_APP_TITLE}`,
    })

    const { width, height } = useWindowSize()
    const headerIsFixed = ref(false)
    const headerHeight = ref(0)

    const routerViewMarginTop = computed(() => {
      if (!headerIsFixed.value) return 0
      else return headerHeight.value
    })

    const routerViewStyle = computed(() => {
      return `margin-top: ${routerViewMarginTop.value}px;`
    })

    const onFixedHeaderChange = (isFixed) => {
      headerIsFixed.value = isFixed
    }

    watch(
      () => width.value,
      () => {
        const headerElement = document.querySelector('#app-header')
        headerHeight.value = headerElement.offsetHeight
      }
    )
    watch(
      () => height.value,
      () => {
        const headerElement = document.querySelector('#app-header')
        headerHeight.value = headerElement.offsetHeight
      }
    )
    onMounted(() => {
      const headerElement = document.querySelector('#app-header')
      headerHeight.value = headerElement.offsetHeight
    })

    return {
      routerViewStyle,
      onFixedHeaderChange,
      headerIsFixed,
    }
  },
  data() {
    return {
      appUpdateAlertEnabled: false,
    }
  },
  mounted() {
    this.appUpdateAlertEnabled = 'serviceWorker' in navigator
  },
}
</script>
<style lang="scss">
@import 'scss/styles.scss';

.app {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.router-view {
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 0;
  margin-bottom: 0.5rem;
}

.heading-wrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0.35rem 0;
  color: #cac6b8;
}

.heading {
  @include font-size(2rem);
  font-weight: 400;
  margin: 0;
}

.heading-link {
  color: $font-color;
  text-decoration: none;

  &:hover {
    color: $font-color;
  }
}

.sub-heading {
  @include font-size(1.5rem);
  font-weight: 400;
  margin: 0;
}
</style>
