import {
  useDuelLeaderboardStore,
  useTeamfightLeaderboardStore,
} from '@/stores/leaderboard'

import {
  useDuelAllTimeLeaderboardStore,
  useDuelPeakMmrLeaderboardStore,
  useTeamfightAllTimeLeaderboardStore,
  useTeamfightPeakMmrLeaderboardStore,
} from '@/stores/unofficialLeaderboard'
import { LEADERBOARD_TYPE, QUEUE_TYPE } from '@/_helpers/enums'

export const useStoreMap = {
  [`${QUEUE_TYPE.DUEL}${LEADERBOARD_TYPE.CURRENT_TOP_100}`]:
    useDuelLeaderboardStore,
  [`${QUEUE_TYPE.TEAMFIGHT}${LEADERBOARD_TYPE.CURRENT_TOP_100}`]:
    useTeamfightLeaderboardStore,
  [`${QUEUE_TYPE.DUEL}${LEADERBOARD_TYPE.ALL_TIME}`]:
    useDuelAllTimeLeaderboardStore,
  [`${QUEUE_TYPE.TEAMFIGHT}${LEADERBOARD_TYPE.ALL_TIME}`]:
    useTeamfightAllTimeLeaderboardStore,
  [`${QUEUE_TYPE.DUEL}${LEADERBOARD_TYPE.PEAK_MMR}`]:
    useDuelPeakMmrLeaderboardStore,
  [`${QUEUE_TYPE.TEAMFIGHT}${LEADERBOARD_TYPE.PEAK_MMR}`]:
    useTeamfightPeakMmrLeaderboardStore,
}
