import { createApp } from 'vue'
import App from '@/App.vue'
import '@/registerServiceWorker'
import router from '@/router'
import config from '@/config/config.js'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import VueFeather from 'vue-feather'
import Notifications from '@kyvg/vue3-notification'
import VueLazyLoad from 'vue3-lazyload'

// Google Analytics
import VueGtag from 'vue-gtag'

import { createHead } from '@unhead/vue'
const head = createHead()

const app = createApp(App)

if (config.analytics.enabled) {
  let analyticsConfiguration = {
    config: {
      id: config.trackingId,
      params: {
        send_page_view: true,
      },
      autoTracking: {
        skipSamePath: true,
      },
    },
  }
  app.use(VueGtag, analyticsConfiguration, router)
}

const loadingImage = require('../public/img/no_avatar_small.jpg')
app.use(VueLazyLoad, {
  loading: loadingImage,
  error: loadingImage,
  lifecycle: {
    error: () => {},
  },
})

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)
app.use(pinia)
app.use(router)
app.use(head)
app.use(Notifications)
app.component(VueFeather.name, VueFeather)
router.isReady().then(() => app.mount('#app'))

export default app
