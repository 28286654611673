// @preval

const RANK_LEAGUES = Object.freeze([
  'Bronze',
  'Silver',
  'Gold',
  'Platinum',
  'Diamond',
  'Elite',
])
const RANK_DIVISIONS = Object.freeze(['I', 'II', 'III', 'IV', 'V'])

const BASE_MMR_OFFSET = 100
const LEAGUE_MMR_OFFSET = 500
const DIVISION_MMR_OFFSET = 100
const LEAGUE_COUNT = 6
const DIVISION_COUNT = 5

function makeLabel(league, division) {
  return `${league} ${division}`
}

function makeShortLabel(league, division) {
  return `${league.charAt(0)}${RANK_DIVISIONS.indexOf(division) + 1}`
}

function calculateRankLabels(makeLabel) {
  const rankLabels = {}

  for (let league = 0; league < LEAGUE_COUNT; league++) {
    for (let division = 0; division < DIVISION_COUNT; division++) {
      const mmrThreshold =
        BASE_MMR_OFFSET +
        league * LEAGUE_MMR_OFFSET +
        division * DIVISION_MMR_OFFSET
      rankLabels[mmrThreshold] = makeLabel(
        RANK_LEAGUES[league],
        RANK_DIVISIONS[division]
      )
    }
  }

  return rankLabels
}

const RANK_LABELS = Object.freeze({
  0: 'Unranked',
  ...calculateRankLabels(makeLabel),
})
const RANK_LABELS_SHORT = Object.freeze({
  0: '-',
  ...calculateRankLabels(makeShortLabel),
})

const MAX_MMR_THRESHOLD =
  (LEAGUE_COUNT - 1) * LEAGUE_MMR_OFFSET + DIVISION_COUNT * DIVISION_MMR_OFFSET

const RANK_DECAY_WAIT = 7 * 24 * 60 * 60 * 1000

module.exports = {
  RANK_LEAGUES,
  RANK_DIVISIONS,
  MAX_MMR_THRESHOLD,
  RANK_LABELS,
  RANK_LABELS_SHORT,
  RANK_DECAY_WAIT,
}
