/* eslint-disable no-console */

import { register } from 'register-service-worker'
import { GLOBAL_EVENT } from '@/_helpers/enums.js'
import config from '@/config/config.js'

if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'local') {
  const checkForUpdate = (registration) => {
    console.log('Looking for App updates...')
    registration.update()
  }

  register(`${process.env.BASE_URL}precache-manifest-service-worker.js`, {
    ready() {
      console.log('App is being served from cache by a service worker.')
    },
    registered(registration) {
      console.log('Service worker has been registered.')
      checkForUpdate(registration)
      setInterval(
        () => checkForUpdate(registration),
        config.appUpdateCheckInterval
      )
    },
    cached() {
      console.log('Content has been cached for offline use.')
    },
    updatefound(data) {
      console.log('New content is downloading.', data, JSON.stringify(data))
    },
    updated(registration) {
      console.log(
        'New content is available; please refresh.',
        registration,
        JSON.stringify(registration)
      )
      document.dispatchEvent(
        new CustomEvent(GLOBAL_EVENT.SERVICE_WORKER_UPDATE_READY, {
          detail: registration,
        })
      )
    },
    offline() {
      console.log(
        'No internet connection found. App is running in offline mode.'
      )
    },
    error(error) {
      console.error('Error during service worker registration:', error)
    },
  })
}
