import rankHelper from '@/_helpers/rankHelperPreval'

export function getLabelForMmr(mmr) {
  return rankHelper.RANK_LABELS[getDivisionThreshold(mmr)]
}

export function getDivisionThreshold(mmr) {
  let divisionThreshold
  if (mmr >= rankHelper.MAX_MMR_THRESHOLD)
    divisionThreshold = rankHelper.MAX_MMR_THRESHOLD
  else divisionThreshold = mmr - (mmr % 100)
  return divisionThreshold
}

export const RANK_LEAGUES = rankHelper.RANK_LEAGUES
export const RANK_DIVISIONS = rankHelper.RANK_DIVISIONS
export const MAX_MMR_THRESHOLD = rankHelper.MAX_MMR_THRESHOLD
export const RANK_LABELS = rankHelper.RANK_LABELS
export const RANK_LABELS_SHORT = rankHelper.RANK_LABELS_SHORT
export const RANK_DECAY_WAIT = rankHelper.RANK_DECAY_WAIT

export const MMR_THRESHOLDS = Object.freeze([2600, 2100, 1600, 1100, 600, 0])
