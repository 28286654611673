<template>
  <div class="entry-wrapper">
    <div v-if="deltaEnabled" class="delta-wrapper">
      <div
        v-if="delta"
        class="delta"
        :class="[delta.position < 0 ? 'gain' : 'loss']"
      >
        <div
          v-if="!delta.newEntry && delta.position != 0"
          class="delta-arrow"
        ></div>
        <div v-if="!delta.newEntry && delta.position != 0">
          {{ Math.abs(delta.position) }}
        </div>
        <ScribeTooltip
          v-if="delta.newEntry"
          class="new-player-icon"
          :content="newPlayerTooltip"
        >
          ✧
        </ScribeTooltip>
      </div>
    </div>
    <div class="entry-position entry-text">{{ entry.Position }}</div>
    <div class="entry-data">
      <div class="entry-square-image-wrapper" :class="{ inactive: isInactive }">
        <router-link :to="'/player/' + entry.PlayFabId">
          <img
            v-lazy="{ src: entry.avatar || '/img/no_avatar_small.jpg' }"
            class="player-avatar"
            :alt="entry.name"
          />
        </router-link>
      </div>
      <div
        class="entry-name entry-text"
        :class="{ inactive: isInactive, banned: entry.banned }"
        :id="entry.PlayFabId + '-name'"
      >
        <router-link
          class="leaderboard-link"
          :to="'/player/' + entry.PlayFabId"
          >{{ entry.name }}</router-link
        >
      </div>
      <ScribeTooltip
        v-if="country"
        :content="country.name"
        class="entry-country-image-wrapper"
      >
        <img
          :alt="entry.countryCode"
          :src="country.imageUrl"
          onerror="this.style.display='none'"
        />
      </ScribeTooltip>
      <div v-if="deltaEnabled" class="delta-wrapper mmr-delta">
        <div
          v-if="delta"
          class="delta"
          :class="[delta.mmr > 0 ? 'gain' : 'loss']"
        >
          <div v-if="delta.mmr != 0" class="delta-arrow"></div>
          <div v-if="delta.mmr != 0">{{ Math.abs(delta.mmr) }}</div>
        </div>
      </div>
      <div class="entry-mmr entry-text" :class="mmrClass">{{ entry.MMR }}</div>
      <div class="entry-square-image-wrapper">
        <RankImage :mmr="entry.MMR" :defaultSize="64" />
      </div>
      <div v-if="deltaEnabled" class="entry-win-loss-wrapper">
        <span v-if="games > 0">{{ delta.wins + '·' + delta.losses }}</span>
      </div>
      <ScribeTooltip v-if="timestampEnabled" class="entry-timestamp-wrapper">
        {{ timestampFromNow }}
        <template #content>
          {{ timestampTooltip }}
          <br />
          {{ timestampDate }}
        </template>
      </ScribeTooltip>
    </div>
  </div>
</template>

<script>
import { countryByCode } from '@/_helpers/accountHelper'
import { DEFAULT_PLAYER_DELTA, LEADERBOARD_TYPE } from '@/_helpers/enums'
import { MMR_THRESHOLDS, RANK_DECAY_WAIT } from '@/_helpers/rankHelper'
import DateFnsWrapper from '@/_helpers/dateFnsWrapper'
import RankImage from '@/components/RankImage'
import { computed } from 'vue'
import { useStoreMap } from '@/stores'
import ScribeTooltip from '@/components/ScribeTooltip.vue'

export default {
  components: {
    RankImage,
    ScribeTooltip,
  },
  props: {
    deltaEnabled: {
      type: Boolean,
      required: true,
    },
    timestampEnabled: {
      type: Boolean,
      default: false,
    },
    queueType: {
      type: String,
      required: true,
    },
    leaderboardType: {
      type: String,
      required: true,
    },
    entry: {
      required: true,
    },
  },
  setup(props) {
    const store = useStoreMap[`${props.queueType}${props.leaderboardType}`]()

    const currentDelta = computed(() => store.currentDelta)
    const selectedDelta = computed(() => store.selectedDelta)

    const delta = computed(() => {
      return !currentDelta.value || !store.currentDelta.deltas
        ? DEFAULT_PLAYER_DELTA
        : store.currentDelta.deltas[props.entry.PlayFabId]
    })

    const games = computed(() => {
      if (!delta.value) return 0
      return delta.value.wins + delta.value.losses
    })
    const newPlayerTooltip = computed(() => {
      return `This player wasn't in the top 100 ${
        store.labelByDelta[store.selectedDelta]
      } ago`
    })
    const mmrClass = computed(() => {
      const mmr = props.entry.MMR

      for (const mmrThreshold of MMR_THRESHOLDS) {
        if (mmr >= mmrThreshold) return `mmr-${mmrThreshold}`
      }

      return ''
    })
    const country = computed(() => {
      if (props.entry.countryCode) return countryByCode(props.entry.countryCode)
      return null
    })
    const timestampDate = computed(() => {
      if (!props.timestampEnabled) return null
      return new Date(props.entry.timestamp || props.entry.lastPlayed)
    })
    const timestampFromNow = computed(() => {
      if (!props.timestampEnabled) return null
      return DateFnsWrapper.formatDistanceToNow(timestampDate.value)
    })
    const timestampTooltip = computed(() => {
      if (!props.timestampEnabled) return null
      if (props.leaderboardType == LEADERBOARD_TYPE.ALL_TIME)
        return `Last played ${timestampFromNow.value} ago`
      if (props.leaderboardType == LEADERBOARD_TYPE.PEAK_MMR)
        return `First reached ${timestampFromNow.value} ago`
      return ''
    })
    const isInactive = computed(() => {
      if (props.leaderboardType != LEADERBOARD_TYPE.ALL_TIME) return false
      return +timestampDate.value + RANK_DECAY_WAIT < +new Date()
    })

    return {
      store,
      selectedDelta,
      delta,
      games,
      newPlayerTooltip,
      mmrClass,
      country,
      timestampFromNow,
      timestampDate,
      timestampTooltip,
      isInactive,
    }
  },
}
</script>

<style lang="scss" scoped>
$rfs-base-value: 0.6rem;
@import '~rfs/scss';

$entry-height: 2.5em;

$leaderboard-link-hover-font-color: #f0ead7;

.entry-wrapper {
  display: flex;
  justify-content: center;
  height: $entry-height;
  align-items: center;
  margin: 0.3em 0;
  max-width: $leaderboard-entry-width;
  width: 100%;
  min-width: 0;
}

.entry-position {
  text-align: center;
  width: 1.7em;
  flex-shrink: 0;
}

.entry-data {
  display: flex;
  flex-grow: 4;
  height: 100%;
  min-width: 0;
  max-width: $base-width;
  background-color: #0f1010;
  border: 1px solid #0f1010;
  border-top-left-radius: $avatar-border-radius;
  border-bottom-left-radius: $avatar-border-radius;
  align-items: center;

  -moz-box-shadow: inset 0 0 0.2em #000000;
  -webkit-box-shadow: inset 0 0 0.2em #000000;
  box-shadow: inset 0 0 0.2em #000000;
}

.entry-square-image-wrapper {
  display: flex;
  width: $entry-height;
  height: $entry-height;
  min-width: $entry-height;
  min-height: $entry-height;
  align-items: center;
  justify-content: center;
}

.player-avatar {
  border-radius: $avatar-border-radius;
  max-width: 95%;
  max-height: 95%;
  display: block;
}

.entry-name {
  padding-left: 0.5em;
  text-align: start;
  max-width: 24em;
  flex-grow: 3;
  flex-shrink: 4;
  white-space: nowrap;
  overflow: hidden;
  min-width: 0;
  text-overflow: ellipsis;
}

$flag-width: 1.3em;
.entry-country-image-wrapper {
  display: flex;
  width: $flag-width;
  height: $entry-height;
  min-width: $flag-width;
  min-height: $entry-height;
  align-items: center;
  justify-content: center;
}

.mmr-delta {
  margin: 0 0.35vw 0 0.5vw;
}

.entry-mmr {
  margin-right: 0.35vw;
}

.new-player-icon {
  @include font-size(1rem);
  color: rgb(120, 205, 245);
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  cursor: default;
}

.entry-win-loss-wrapper {
  @include font-size(1rem);
  @include rfs(3.5rem, width);
  @include rfs(3.5rem, min-width);
  text-align: center;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.entry-timestamp-wrapper {
  @include font-size(1.2rem);
  @include rfs(8rem, width);
  @include rfs(7rem, min-width);
  overflow: hidden;
  display: flex;
  text-align: end;
  justify-content: flex-end;
  align-items: center;
  padding-right: 0.5rem;

  @media (max-width: $base-width) {
    padding-right: 0.1rem;
  }
}

.inactive > a {
  color: darken($font-color, 40%);
  text-decoration: none;

  &:hover {
    color: darken($leaderboard-link-hover-font-color, 15%);
    text-decoration: underline;
  }
}

.banned > a {
  color: $banned-font-color;
  text-decoration: line-through;

  &:hover {
    color: darken($banned-font-color, 15%);
    text-decoration: line-through;
  }
}

.leaderboard-link {
  color: $font-color;
  text-decoration: none;

  &:hover {
    color: $leaderboard-link-hover-font-color;
    text-decoration: underline;
  }
}
</style>
