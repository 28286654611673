<template>
  <div class="navigation-wrapper flex-vertical">
    <UsageHint />
  </div>
</template>

<script>
import UsageHint from '@/components/UsageHint'

export default {
  components: {
    UsageHint,
  },
}
</script>

<style lang="scss" scoped>
.navigation-wrapper {
  @include font-size(1.2rem);
}
</style>
