import { createRouter, createWebHistory } from 'vue-router'
import DuelLeaderboardPage from '@/views/DuelLeaderboardPage.vue'
import DuelAllTimeLeaderboardPage from '@/views/DuelAllTimeLeaderboardPage.vue'
import DuelPeakMmrLeaderboardPage from '@/views/DuelPeakMmrLeaderboardPage.vue'
import TeamfightLeaderboardPage from '@/views/TeamfightLeaderboardPage.vue'
import TeamfightAllTimeLeaderboardPage from '@/views/TeamfightAllTimeLeaderboardPage.vue'
import TeamfightPeakMmrLeaderboardPage from '@/views/TeamfightPeakMmrLeaderboardPage.vue'

const PlayerProfilePage = () => import('@/views/PlayerProfilePage.vue')
const HistoricalTopXPage = () => import('@/views/HistoricalTopXPage.vue')

import NotFoundPage from '@/views/NotFoundPage.vue'

import { QUEUE_TYPE, LEADERBOARD_TYPE } from '@/_helpers/enums'
import { useMainStore } from '@/stores/main'

const routes = [
  {
    path: '/',
    redirect: () => {
      const mainStore = useMainStore()
      return `/${mainStore.selectedQueueType}`
    },
  },
  {
    path: '/duel',
    name: 'DuelLeaderboardPage',
    component: DuelLeaderboardPage,
    beforeEnter: (to, from, next) => {
      const mainStore = useMainStore()
      mainStore
        .setLeaderboardContext({
          type: LEADERBOARD_TYPE.CURRENT_TOP_100,
          queueType: QUEUE_TYPE.DUEL,
        })
        .then(next)
        .catch(next)
    },
  },
  {
    path: '/teamfight',
    name: 'TeamfightLeaderboardPage',
    component: TeamfightLeaderboardPage,
    beforeEnter: (to, from, next) => {
      const mainStore = useMainStore()
      mainStore
        .setLeaderboardContext({
          type: LEADERBOARD_TYPE.CURRENT_TOP_100,
          queueType: QUEUE_TYPE.TEAMFIGHT,
        })
        .then(next)
        .catch(next)
    },
  },
  {
    path: '/duelAllTime',
    name: 'DuelAllTimeLeaderboardPage',
    component: DuelAllTimeLeaderboardPage,
    beforeEnter: (to, from, next) => {
      const mainStore = useMainStore()
      mainStore
        .setLeaderboardContext({
          type: LEADERBOARD_TYPE.ALL_TIME,
          queueType: QUEUE_TYPE.DUEL,
        })
        .then(next)
        .catch(next)
    },
  },
  {
    path: '/teamfightAllTime',
    name: 'TeamfightAllTimeLeaderboardPage',
    component: TeamfightAllTimeLeaderboardPage,
    beforeEnter: (to, from, next) => {
      const mainStore = useMainStore()
      mainStore
        .setLeaderboardContext({
          type: LEADERBOARD_TYPE.ALL_TIME,
          queueType: QUEUE_TYPE.TEAMFIGHT,
        })
        .then(next)
        .catch(next)
    },
  },
  {
    path: '/duelPeakMmr',
    name: 'DuelPeakMmrLeaderboardPage',
    component: DuelPeakMmrLeaderboardPage,
    beforeEnter: (to, from, next) => {
      const mainStore = useMainStore()
      mainStore
        .setLeaderboardContext({
          type: LEADERBOARD_TYPE.PEAK_MMR,
          queueType: QUEUE_TYPE.DUEL,
        })
        .then(next)
        .catch(next)
    },
  },
  {
    path: '/teamfightPeakMmr',
    name: 'TeamfightPeakMmrLeaderboardPage',
    component: TeamfightPeakMmrLeaderboardPage,
    beforeEnter: (to, from, next) => {
      const mainStore = useMainStore()
      mainStore
        .setLeaderboardContext({
          type: LEADERBOARD_TYPE.PEAK_MMR,
          queueType: QUEUE_TYPE.TEAMFIGHT,
        })
        .then(next)
        .catch(next)
    },
  },
  {
    path: '/player/:identifier',
    name: 'PlayerProfilePage',
    component: PlayerProfilePage,
    props: true,
  },
  {
    path: '/history',
    name: 'HistoricalTopXPage',
    component: HistoricalTopXPage,
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFoundPage,
  },
]

const router = createRouter({
  history: createWebHistory(),
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0, left: 0 }
    }
  },
})

export default router
